<template data-app>
<div class="d-flex flex-column flex-root">
    <div
      class="login login-1 login-signin-on d-flex flex-column  flex-row-fluid"
      id="kt_login"
    >
      <!--begin::Content-->
      <KTHeader></KTHeader>
      <div
        class="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden"
      >
        <div class="d-flex flex-column-fluid flex-center mt-20 mb-10">
            <v-app>
              <!--begin::Content header-->
            
              <!--end::Content header-->


                <!--begin::Signup-->
                <div class="register-form">
                  <div class="step-header">
                      <div class="step-header-title">
                    
                                <v-icon v-if="step != 1" @click="beforeStep()">mdi-arrow-left</v-icon>
                                <v-icon v-if="step == 1">ss</v-icon>
                                <p v-if="step !=3">Registro</p><p v-if="step ==3">Seguridad</p><v-icon @click="landing">mdi-close</v-icon>
                      </div>
                      <div>
                        <div class="steps">
                          <v-btn class="step1-button"  fab>
                            <span v-if="step != 1" class="material-icons" style="font-size:35px;">
                            check_circle
                            </span>
                            <span v-if="step == 1" class="material-icons" style="font-size:35px;">
                            face
                            </span>
                          </v-btn><hr style="border-top: 2px dotted black"/>
                            <v-btn class="step2-button" v-bind:style=" step !=1  ? 'background-color: #5F3FB1 !important;' :'background-color: #CBD5E1 !important;' " fab>
                              <span v-if="step == 3" class="material-icons" style="font-size:35px;">
                              check_circle
                              </span>
                              <span v-if="step != 3"  class="material-icons" style="font-size:35px;">
                                supervisor_account
                              </span>
                          </v-btn><hr style="border-top: 2px dotted black"/>
                            <v-btn class="step3-button"  v-bind:style=" step ==3  ? 'background-color: #5c77e5 !important;' :'background-color: #CBD5E1 !important;' "  fab dark>
                            <span class="material-icons" style="font-size:35px;">
                            lock
                            </span>
                          </v-btn>
                        </div>
                        <div class="step-title">
                          <p v-bind:style=" step == 1  ? 'color: #5F3FB1;' :'' ">Información del estudiante</p>
                          <p v-bind:style=" step == 2  ? 'color: #5F3FB1;' :'' ">Información parental</p>
                          <p v-bind:style=" step == 3  ? 'color: #5F3FB1;' :'' ">Información de la cuenta</p>
                        </div>
                                  
                      </div>
                  </div>
                  <div class="step-body">
                      <div class="step1" v-if="step == 1">
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-text-field
                                  outlined
                                  single-line
                                  label="Nombre"
                                  v-model="form.firstName"
                                  prepend-inner-icon="mdi-account"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                  outlined
                                  single-line
                                  label="Apellido"
                                  v-model="form.lastName"
                                  prepend-inner-icon="mdi-account"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                  outlined
                                  single-line
                                  label="Fecha de nacimiento"
                                  v-model="form.birthday"
                                  prepend-inner-icon="mdi-emoticon"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-select
                                  v-model="form.gender"
                                  :items="['masculino', 'femenino']"
                                  label="Género"
                                  outlined
                                  single-line
                                  prepend-inner-icon="mdi-emoticon"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                  outlined
                                  single-line
                                  label="Escuela"
                                  v-model="form.school"
                                  prepend-inner-icon="mdi-book-open-variant"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-select
                                  v-model="form.grade"
                                  :items="gradeItems"
                                  label="Grado escolar"
                                  outlined
                                  single-line
                                  prepend-inner-icon="mdi-school"
                                ></v-select>
                            </v-col>
                          </v-row>
                          <div class="d-flex continue">
                            <router-link to="login" class="login-des">¿Ya tienes una cuenta?</router-link>
                            <v-btn rounded   style="width:200px !important"  class="text-capitalize contiuneButton" @click="nextStep(2)">Continuar<span class="material-icons">arrow_forward</span></v-btn>
                          </div>
                        
                      </div>
                      <div class="step2" v-if="step == 2">
                        <v-row>
                            <v-col cols="12" md="6">
                              <v-text-field
                                  outlined
                                  single-line
                                  label="Nombre del padre/madre/Tutor"
                                  v-model="form.parentFirstName"
                                  prepend-inner-icon="mdi-account"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                  outlined
                                  single-line
                                  v-model="form.parentLastName"
                                  label="Apellido del padre/madre/Tutor"
                                  prepend-inner-icon="mdi-account"
                                ></v-text-field>
                            </v-col>
                            <div class="register-border"></div>
                            <v-col cols="12" md="6">
                              <v-select
                                  v-model="form.country"
                                  :items="['Afghanistan',
                                  'Albania',
                                  'Algeria',
                                  'Andorra',
                                  'Angola',
                                  'Antigua and Barbuda',
                                  'Argentina',
                                  'Armenia',
                                  'Australia',
                                  'Austria',
                                  'Azerbaijan',
                                  'The Bahamas',
                                  'Bahrain',
                                  'Bangladesh',
                                  'Barbados',
                                  'Belarus',
                                  'Belgium',
                                  'Belize',
                                  'Benin',
                                  'Bhutan',
                                  'Bolivia',
                                  'Bosnia and Herzegovina',
                                  'Botswana',
                                  'Brazil',
                                  'Brunei',
                                  'Bulgaria',
                                  'Burkina Faso',
                                  'Burundi',
                                  'Cabo Verde',
                                  'Cambodia',
                                  'Cameroon',
                                  'Canada',
                                  'Central African Republic',
                                  'Chad',
                                  'Chile',
                                  'China',
                                  'Colombia',
                                  'Comoros',
                                  'Congo, Democratic Republic of the',
                                  'Congo, Republic of the',
                                  'Costa Rica',
                                  'Côte d’Ivoire',
                                  'Croatia',
                                  'Cuba',
                                  'Cyprus',
                                  'Czech Republic',
                                  'Denmark',
                                  'Djibouti',
                                  'Dominica',
                                  'Dominican Republic',
                                  'East Timor (Timor-Leste)',
                                  'Ecuador',
                                  'Egypt',
                                  'El Salvador',
                                  'Equatorial Guinea',
                                  'Eritrea',
                                  'Estonia',
                                  'Eswatini',
                                  'Ethiopia',
                                  'Fiji',
                                  'Finland',
                                  'France',
                                  'Gabon',
                                  'The Gambia',
                                  'Georgia',
                                  'Germany',
                                  'Ghana',
                                  'Greece',
                                  'Grenada',
                                  'Guatemala',
                                  'Guinea',
                                  'Guinea-Bissau',
                                  'Guyana',
                                  'Haiti',
                                  'Honduras',
                                  'Hungary',
                                  'Iceland',
                                  'India',
                                  'Indonesia',
                                  'Iran',
                                  'Iraq',
                                  'Ireland',
                                  'Israel',
                                  'Italy',
                                  'Jamaica',
                                  'Japan',
                                  'Jordan',
                                  'Kazakhstan',
                                  'Kenya',
                                  'Kiribati',
                                  'Korea, North',
                                  'Korea, South',
                                  'Kosovo',
                                  'Kuwait',
                                  'Kyrgyzstan',
                                  'Laos',
                                  'Latvia',
                                  'Lebanon',
                                  'Lesotho',
                                  'Liberia',
                                  'Libya',
                                  'Liechtenstein',
                                  'Lithuania',
                                  'Luxembourg',
                                  'Madagascar',
                                  'Malawi',
                                  'Malaysia',
                                  'Maldives',
                                  'Mali',
                                  'Malta',
                                  'Marshall Islands',
                                  'Mauritania',
                                  'Mauritius',
                                  'Mexico',
                                  'Micronesia, Federated States of',
                                  'Moldova',
                                  'Monaco',
                                  'Mongolia',
                                  'Montenegro',
                                  'Morocco',
                                  'Mozambique',
                                  'Myanmar (Burma)',
                                  'Namibia',
                                  'Nauru',
                                  'Nepal',
                                  'Netherlands',
                                  'New Zealand',
                                  'Nicaragua',
                                  'Niger',
                                  'Nigeria',
                                  'North Macedonia',
                                  'Norway',
                                  'Oman',
                                  'Pakistan',
                                  'Palau',
                                  'Panama',
                                  'Papua New Guinea',
                                  'Paraguay',
                                  'Peru',
                                  'Philippines',
                                  'Poland',
                                  'Portugal',
                                  'Qatar',
                                  'Romania',
                                  'Russia',
                                  'Rwanda',
                                  'Saint Kitts and Nevis',
                                  'Saint Lucia',
                                  'Saint Vincent and the Grenadines',
                                  'Samoa',
                                  'San Marino',
                                  'Sao Tome and Principe',
                                  'Saudi Arabia',
                                  'Senegal',
                                  'Serbia',
                                  'Seychelles',
                                  'Sierra Leone',
                                  'Singapore',
                                  'Slovakia',
                                  'Slovenia',
                                  'Solomon Islands',
                                  'Somalia',
                                  'South Africa',
                                  'Spain',
                                  'Sri Lanka',
                                  'Sudan',
                                  'Sudan, South',
                                  'Suriname',
                                  'Sweden',
                                  'Switzerland',
                                  'Syria',
                                  'Taiwan',
                                  'Tajikistan',
                                  'Tanzania',
                                  'Thailand',
                                  'Togo',
                                  'Tonga',
                                  'Trinidad and Tobago',
                                  'Tunisia',
                                  'Turkey',
                                  'Turkmenistan',
                                  'Tuvalu',
                                  'Uganda',
                                  'Ukraine',
                                  'United Arab Emirates',
                                  'United Kingdom',
                                  'United States',
                                  'Uruguay',
                                  'Uzbekistan',
                                  'Vanuatu',
                                  'Vatican City',
                                  'Venezuela',
                                  'Vietnam',
                                  'Yemen',
                                  'Zambia',
                                  'Zimbabwe']"
                                  label="País"
                                  outlined
                                  single-line
                                  prepend-inner-icon="mdi-map-marker"
                              ></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                  outlined
                                  single-line
                                  label="Ciudad"
                                  v-model="form.city"
                                  prepend-inner-icon="mdi-map-marker"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                  outlined
                                  single-line
                                  v-model="form.parentPhoneNumber"
                                  label="Número de celular / Whats App"
                                  prepend-inner-icon="mdi-cellphone"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                  outlined
                                  single-line
                                  v-model="form.parentEmail"
                                  label="Correo electrónico"
                                  prepend-inner-icon="mdi-email"
                                ></v-text-field>
                            </v-col>
                          </v-row>
                          <div class="d-flex continue">
                            <router-link to="#" v-if="step == 1">¿Ya tienes una cuenta?</router-link>
                            <v-icon v-if="step == 2">xxxx</v-icon>
                            <v-btn rounded  style="width:200px !important" class="text-capitalize contiuneButton" @click="nextStep(3)">Continuar<span class="material-icons">arrow_forward</span></v-btn>
                          </div>
                      </div>
                      <div class="step3" v-if="step == 3">
                        <v-form ref="form" lazy-validation
                          @submit.prevent="onSubmit">
                          <v-row>
                            <v-col cols="12">
                              <div
                                role="alert"
                                v-bind:class="{ 'd-none': !errors.length, 'show': errors.length}"
                                class="alert alert-danger mt-4"
                              >
                                <div class="alert-text" v-for="(error, i) in errors" :key="i">
                                  {{ error }}
                                </div>
                              </div>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                  outlined
                                  single-line
                                  v-model="form.email"
                                  label="Correo electrónico*"
                                  :rules="form.emailRules"
                                  prepend-inner-icon="mdi-account"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                  outlined
                                  single-line
                                  type="password"
                                  v-model="form.password"
                                  label="Contraseña*"
                                  :rules="form.passwordRules"
                                  prepend-inner-icon="mdi-email"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field
                                  outlined
                                  single-line
                                  type="password"
                                  v-model="form.confirmPassword"
                                  label="Confirmar Contraseña*"
                                  :rules="[passwordConfirmationRule]"
                                  prepend-inner-icon="mdi-email"
                                ></v-text-field>
                            </v-col>
                          </v-row>
                          <div class="login-action d-flex continue">
                            <div>
                              <ul>
                                <li class="py-1">8 dígitos</li>
                                <li class="py-1">Al menos una mayúscula</li>
                              </ul> 
                            </div>
                            <button  ref="kt_login_signup_submit"
                                class="signup-button btn btn-success text-capitalize font-weight-bold py-4 px-24 mb-5 font-size-3 rounded-pill">Registrar y comenzar</button>
                          </div>
                        </v-form>

                      </div>
                  </div>
                  
                </div>
                <!--end::Signup-->

            </v-app>
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>
<style lang="scss">
@import "@/assets/sass/pages/login/register.scss";
@import "@/assets/sass/pages/login/login-1.scss";
</style>


<script>
import { mapState } from "vuex";
import { REGISTER } from "@/core/services/store/auth.module";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTHeader from "@/view/layout/header/Header.vue";
import Swal from 'sweetalert2'

export default {
  name: "register",
  components: {
    KTHeader,
  },
  data() {
    return {
      // Remove this dummy login info
      form: {
        grade: null,
        firstName: "",
        gender: "masculino",
        lastName: "",
        birthday: "",
        parentFirstName: "",
        parentLastName: "",
        country: "Mexico",
        city: "",
        parentPhoneNumber: "",
        parentEmail: "",
        email: "",
        password: "",
        confirmPassword: "",
        school: "",
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /^(?!.*\s).+@.+\..+$/.test(v) || 'E-mail must be valid',
        ],
        passwordRules: [
          v => !!v || "Password is required",
          v => (v && v.length > 7) || "Password must be bigger than 8 characters",
          v => (v && /[A-Z]{1}/.test(v)) || 'At least one capital latter',
        ],
      },
      gradeItems: [1, 2, 3, 4, 5, 6],
      step:1,
      step1:false,
      step2:false,
    };
  },
  methods: {
    landing() {
      this.$router.go(-1)
    },
    onSubmit() {

      if (!this.$refs.form.validate()) {
        Swal.fire('Something went wrong.')

        return;
      }

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signup_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

            // send register request
        this.$store
          .dispatch(REGISTER, {
            first_name: this.form.firstName,
            last_name: this.form.lastName,
            email: this.form.email,
            birthday: this.form.birthday,
            gender: this.form.gender,
            grade: this.form.grade,
            county: this.form.country,
            city: this.form.city,
            parent_first_name: this.form.parentFirstName,
            parent_last_name: this.form.parentLastName,
            parent_phone_number: this.form.parentPhoneNumber,
            parent_email: this.form.parentEmail,
            password: this.form.lastName,
            school: this.form.school
          }).then(() => {
             submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            Swal.fire('Has terminado tu registro con éxito por favor espera a que te demos acceso y  puedas comenzar sesión.')
            this.$router.push({ name: "login" });
          }).catch(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
    },
    nextStep(val) {
      this.step = val;
    },
    beforeStep() {
      var step = this.step;
      this.step = step - 1;
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    passwordConfirmationRule() {
      return () => (this.form.password === this.form.confirmPassword) || 'Password must match'
    }
  }
};
</script>
